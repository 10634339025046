#breadcrumb {
    @apply 
    sticky 
    top-0 
    bg-white/85 
    backdrop-blur-xl 
    z-[9999] 
    pt-2 
    border-b
    mb-4 ;
}


.bdc-item.active,
.bdc-resp-item.active {
    @apply 
    text-black/80
    ;
}

.bdc-responsive {
    height: 0;
    overflow: hidden;
    padding: 0 2rem;
    transition: all 0.3s;
}

.bdc-item,
.bdc-resp-item {
    @apply 
    text-sm
    text-black/50
    block
    relative
    ;
}

.bdc-item.active:after {
	content: "";
	position: absolute;
	height: 1px;
	width: 100%;
	border-radius: 1px;
	background-color: rgba(0,0,0,.8);
	inset-inline-start: 0;
	bottom: -13px;
	will-change: bottom;
	opacity: 0.8;
}

.bdc-resp-item.active:before {
    content: "";
    position: absolute;
    top: 5px;
    left: -10px;
    display: inline-block;
    height: 10px;
    width: 1px;
    background-color: rgba(0,0,0,.8);
    color: rgba(0,0,0,.8);
}