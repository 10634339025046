.slideUp {
    animation: slideUp 0.5s ease;
}
.slideDown {
    animation: slideDown 0.5s ease;
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideDown {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(100%);
    }
}