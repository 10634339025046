.noise {
    user-select: none;
    pointer-events: none;
}

.screen {
    position: absolute;
    top: 0;
    mask-image: url('../../images/tv.svg');
    user-select: none;
}

.tv{
    animation: slideIn .5s ease-out forwards;
}

@keyframes slideIn {
    0% {
        opacity: 0;
        left: -20rem;
        scale: .5;
        filter: blur(3px);
    }
    100% {
        opacity: 1;
        left: 0;
        scale: 1;
        filter: blur(0px);
    }
    
}