.icon-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.5rem;
    height: 4rem;
    overflow: hidden;
    top: 0;
    left: 27rem;
}

.icon-list {
    bottom: 0;
    position: absolute;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    transition: all 0.8s;
  }

  .icon-list * {
    height: 3rem;
    width: 3rem;
    font-size: 1rem;
    padding: .5rem 0;
  }

  @media screen and (max-width: 640px) {
      .icon-container {
        left: 15.8rem;
        transform: translateY(-.75rem);
      }  

    .icon-list * {
      height: 2rem;
      width: 2rem;
      font-size: 1rem;
      padding: 1rem 0;
    }
  }

  @media screen and (max-width: 360px) {
    .icon-container {
      left: 13.1rem;
      transform: translateY(-1rem);
    }      
  }





  .hero-shadow {
    animation: shadowIn 1s ease-in-out;
  }

  .hero-laptop {
    animation: laptopIn .8s ease-in-out;
  }

  .hero-smartphone {
    animation: smartphoneIn 1s ease-in-out;
  }

  @keyframes laptopIn {
    0% {
      transform: translateX(100%);
      filter: blur(.2rem);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      filter: blur(0);
      opacity: 1;
    }
  }

  @keyframes smartphoneIn {
    0% {
      transform: translateY(-100%);
      filter: blur(.2rem);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      filter: blur(0);
      opacity: 1;
    }
  }

  @keyframes shadowIn {
    0% {
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

