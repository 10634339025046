.offer {
    position: relative;
}

.offer::before {
    content: "Offre";
    @apply
        text-sm  
        px-3 
        py-1 
        rounded-full 
        absolute 
        top-4 
        right-3 
        rotate-[5deg] 
        bg-pink-600 
        text-white;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);

}

.discount {
    @apply block h-24;
}

.discountedPrice {
    @apply 
    text-xl 
    text-pink-600
    font-semibold
    relative
    ;
}

.discountedPrice::after {
    content: "";
    @apply     
    absolute
    top-1/2
    left-0
    w-11/12
    h-[1px]
    bg-pink-600
    rotate-[-10deg]
    ;

}