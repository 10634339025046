.flash-container {
    @apply absolute left-0 right-0 w-full;
}

.flash-message {
    @apply mx-auto max-w-96 flex gap-x-4 items-center px-4 py-3 w-fit rounded;
}

.flash-message.success {
    @apply dark:bg-green-100 bg-green-400 bg-green-200 text-green-700;
}

.flash-message.error {
    @apply bg-red-100 border border-red-400 text-red-700 ;
}

.flash-container.slide-down {
    animation: slideDown .4s ease-in forwards;
}

.flash-container.slide-up {
    animation: slideUp .4s ease-out forwards;
}

  @keyframes slideDown {
    0% {
        top: -100px;
        opacity: 0;
    }
    100% {
        top: 4.5rem;
        opacity: 1;
    }
  }
  
  @keyframes slideUp {
    0% {
        top: 4rem;
        opacity: 1;
    }
    100% {
        top: -100px;
        opacity: 0;
    }
  }

