.es-header-container,
.es-review-background-container {
    @apply 
    dark:!bg-gray-500/20;
}

.es-header-info *,
.es-review-content-line-shortener *{
    @apply 
    dark:!text-white;
}

.es-review-author-block-info-container * {
    @apply 
    dark:!text-white;
}