.responsive-menu-item{
    @apply px-12 py-3 border rounded-l-lg mb-1 text-center transition cursor-pointer
    border-agantar-blue/10  bg-slate-100 hover:bg-agantar-blue hover:text-slate-100
    dark:border-white/10  dark:bg-slate-950 dark:hover:bg-slate-900
    ;
}

#activeIndicator {
    transition: left 0.3s ease-in-out, width 0.3s ease-in-out;
}

.nav a{
    @apply font-base
      text-sm
      sm:text-base
      uppercase
      font-base
      font-light
      text-black/50
      dark:text-agantar-text-white
      ;
  }

  .nav a.active {
    @apply 
    text-agantar-blue
    dark:text-agantar-light-blue
    ;
  }
